





















































































































































































































































import { ref, PropType, watch, defineComponent, onMounted, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import useRepository, { callTypes } from '@wellbeingapp/dashboard/src/composables/useRepository'
import { BaseRepository, repoParams, filterObject } from '@wellbeingapp/shared/src/repositories/baseRepository'
import { BaseEntityModel } from '@wellbeingapp/shared/src/models/entities/baseEntityModel'
import ScheduleBlockModel from '@wellbeingapp/shared/src/models/entities/scheduleBlocksModel'
import moment from 'moment'
import CloneQuestion from '../../components/dashboard/CloneQuestion.vue'
import RetireQuestion from '../../components/dashboard/RetireQuestion.vue'
import { RepositoryFactory } from '@wellbeingapp/shared/src/repositories/repositoryFactory'
import { ScheduleBlockRepository } from '@wellbeingapp/shared/src/repositories/entities/scheduleBlocksRepository'
import usePermissions from '../../composables/usePermissions'
import useGlobalLoading from '../../composables/useGlobalLoading'
import TagRepository from '@wellbeingapp/shared/src/repositories/entities/tagRepository'
import MultiSelectInput from '../inputs/MultiSelectInput.vue'
import FeedbackView from '../dashboard/FeedbackView.vue'
import { useRouter } from '../../composables/useRouter'


export default defineComponent({
  name: 'table-overview',
  data () {
    return {
      errorNotification: ''
    }
  },
  methods: {
    setErrorNotification (message) {
      this.errorNotification = message

      setTimeout(() => {
        this.errorNotification = ''
      }, 4000)
    }
  },
  components: {
    'clone-question': CloneQuestion,
    'retire-question': RetireQuestion,
    'multi-select-input': MultiSelectInput,
    'feedback-view': FeedbackView
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    onEmptyResultsMessage: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: 25,
      required: false
    },
    repo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository<BaseEntityModel>>,
      required: true
    },
    pathPrefix: {
      type: String,
      required: true
    },
    types: {
      type: Array,
      required: false
    },
    statuses: {
      type: Array,
      required: false
    },
    filterList: {
      type: Array,
      required: true
    },
    isSelectable: {
      type: Boolean,
      required: true
    },
    isSearchable: {
      type: Boolean,
      required: false,
      default: false
    },
    scheduleBlock: {
      type: Object,
      required: false
    },
    activeDate: {
      type: String,
      required: false
    },
    isExport: {
      type: Boolean,
      required: false,
      default: false
    },
    isAnalytics: {
      type: Boolean,
      required: false,
      default: false
    },
    value: Array as PropType<BaseEntityModel[]>
  },
  setup (props, { emit }) {
    const isFeedbackViewVisible = ref<boolean>(false)
    const { can } = usePermissions()
    enum Filters { term = 'term', type = 'type', status = 'status', tags = 'tags' }
    const text = ref<string>('')
    const type = ref<string>('')
    const status = ref<string>('')
    const currentPage = ref<number>(1)
    const selected = ref<BaseEntityModel[]>([])
    const tableKey = ref(0)
    let debounce
    const activeDate = ref<any>()
    const selectedTags = ref<Array<any>>([])
    const filterKey = 'title'
    const feedbackId = ref<String>()
    const { route } = useRouter()
    const slug = ref<string>(route.value.params.slug)
    const table = ref(null)
    const lastUsedCellKey = ref(null)
    onMounted(() => {
      // Define the target node to observe
      const targetNode = document.getElementById('questions-right')

      // Verify if the element exists
      if (!targetNode) {
        return
      }

      // Create a callback function to execute when mutations are observed
      const callback = (mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            // Check the display style directly
            const displayStyle = targetNode.style.display
            // Call your clearRows function here if needed
            clearRows()
          }
        }
      }

      // Create an instance of MutationObserver and pass the callback function
      const observer = new MutationObserver(callback)

      // Set up the observer options to observe attribute changes
      const config = { attributes: true, childList: false, subtree: false }

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config)

      // Stop observing when component is unmounted
      onUnmounted(() => observer.disconnect())
    })
    if (props.isAnalytics) {
      activeDate.value = moment.now()
    } else if (!props.activeDate) {
      RepositoryFactory.get(ScheduleBlockRepository).activeScheduleBlock(slug.value).then((res) => {
        activeDate.value = moment(res.date)
      }).catch((e: { response }) => {
        activeDate.value = moment(e.response.data.active_date)
      })
    } else {
      activeDate.value = moment(props.activeDate)

    }

    const checkIfActiveDate = (date: string) => {
      if (date) {
        const inputDate = moment(date, 'YYYY-MM-DD')
        if (inputDate < activeDate.value) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    if (props.isSelectable) {
      props.fields.unshift({ key: 'checkbox', sortable: false, label: '' })
    }

    if (props.pathPrefix === 'question') {
      status.value = 'ACTIVE'
    }

    if (props.pathPrefix === 'feedback') {
      status.value = 'NEW'
    }

    if (props.isAnalytics) {
      props.fields.push({ key: 'analytics', sortable: false, label: '' })
    } else {
      props.fields.push({ key: 'edit', sortable: false, label: '' }, { key: 'dropdown', sortable: false, label: '' })
    }

    let filters : filterObject = {
      term: { type: 'string', value: text.value, filterKey: 'term' },
      type: { type: 'string', value: type.value, filterKey: 'type' },
      status: { type: 'string', value: status.value, filterKey: 'status' },
      tags: { type: 'arrayEntity', value: selectedTags.value, filterKey: 'tags' },
      in_use: { type: 'string', value: ((props.isExport === true ? String(props.isExport) : undefined) || (props.isAnalytics === true ? String(props.isAnalytics) : undefined)), filterKey: 'in_use' }
    }

    if (props.pathPrefix === 'demographic') {
      filters = {
        term: { type: 'string', value: 'true', filterKey: 'only_editable' }
      }
    }

    const params : repoParams = { filters: filters, page: currentPage.value, pageSize: Number(props.perPage), tenant: slug.value }
    const { loading, results, count, doCall } = useRepository(
      props.repo,
      callTypes.getModelArray,
      params
    )

    useGlobalLoading(loading)

    const debounceSearch = (value) => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        text.value = value
        currentPage.value = 1
        filters[Filters.term] = { type: 'string', value: text.value, filterKey: 'term' }
        doCall()
      }, 400)
    }

    watch(() => selectedTags.value, () => {
      currentPage.value = 1
      filters[Filters.tags] = { type: 'arrayEntity', value: selectedTags.value, filterKey: 'tags' }
      doCall()
    })

    watch(() => type.value, () => {
      currentPage.value = 1
      filters[Filters.type] = { type: 'string', value: type.value, filterKey: 'type' }
      doCall()
    }, { deep: true, immediate: true })

    watch(() => status.value, () => {
      currentPage.value = 1
      filters[Filters.status] = { type: 'string', value: status.value, filterKey: 'status' }
      doCall()
    }, { deep: true, immediate: true })

    watch(() => currentPage.value, (first) => {
      params.page = first
      doCall()
    }, { deep: true, immediate: true })
    // @ts-ignore vue bug, only gives types when deconstructing, v-model is not working when deconstructed
    watch(() => props.value, (inputvalue: BaseEntityModel[]) => {
      selected.value = inputvalue
    }, { deep: true, immediate: true })

    doCall()

    const checkBoxChanged = () => {
      emit('input', selected.value)
    }

    const onRowClicked = (item, index, event) => {

      const checkbox: HTMLInputElement | null = document.getElementById('checkbox-' + item.id) as HTMLInputElement
      if (checkbox.disabled) {
        // @ts-ignore
        table.value.unselectRow(index)
      }
    }

    const isDisplayable = (tipId: string) => {
      if (props.pathPrefix === 'question') {
        return false
      } else if (props.pathPrefix === 'tip' /* && (selected.value.length < 1 || tipId === selected.value[0].id) */) {
        return false
      } else {
        return true
      }
    }

    const checkIfIdAlreadyExistsInScheduleBlock = (id: string, scheduleBlock: ScheduleBlockModel) => {
      if (scheduleBlock) {
        if ((scheduleBlock.tips && scheduleBlock.tips.find(x => x.tip.id === id)) || (scheduleBlock.questions && scheduleBlock.questions.find(x => x.question.id === id))) {
          return true
        }
      }
      return false
    }

    const onRowSelected = (selectedRows) => {
      const filteredSelectedRows = selectedRows.filter((selectedRow, index) => {
        const checkbox: HTMLInputElement | null = document.getElementById('checkbox-' + selectedRow.id) as HTMLInputElement

        if (!checkbox.disabled) {
          return selectedRow
        } else {
          // table.value.unselectRow(index)
          checkbox.checked = false

        }
      })
      if (filteredSelectedRows.length > 0) {
        emit('input', filteredSelectedRows)
      } // is an array
    }
    const clearRows = () => {
      // @ts-ignore
      table.value.clearSelected()
      selected.value = []
    }

    const refreshTable = () => {
      const currentStatus = status.value
      status.value = ''
      status.value = currentStatus
      // @ts-ignore
      lastUsedCellKey.value += 1
      // @ts-ignore
      table.value.refresh()
    }

    emit('refreshTable')

    const reloadData = (event: any) => {
      doCall()
    }

    const displayFeedbackViewSidebar = (id: string) => {
      isFeedbackViewVisible.value = true
      feedbackId.value = id
    }

    let index
    let direction

    const previousFeedback = (id: string) => {
      direction = 'PREVIOUS'
      // @ts-ignore
      index = results.value.findIndex(x => x.id === feedbackId.value)
      if (index === 0) {
        if (currentPage.value > 1) {
          currentPage.value--
        }
      } else {
        // @ts-ignore
        feedbackId.value = results.value[index - 1].id
      }
    }
    watch(() => results.value, () => {
      if (index === 0 && direction === 'PREVIOUS') {
        // @ts-ignore
        feedbackId.value = results.value[props.perPage - 1].id
      }
      if (index === props.perPage - 1 && direction === 'NEXT') {
        // @ts-ignore
        feedbackId.value = results.value[0].id
      }
    }, { deep: true, immediate: true })

    const nextFeedback = (id: string) => {
      direction = 'NEXT'
      // @ts-ignore
      index = results.value.findIndex(x => x.id === feedbackId.value)
      if (index === (results.value.length - 1)) {
        if (currentPage.value < (count.value / props.perPage)) {
          currentPage.value++
        }
      } else {
        // @ts-ignore
        feedbackId.value = results.value[index + 1].id
      }
    }

    const shortenContent = (content: string) => {
      if (content.length > 50) {
        content = content.substring(0, 50) + '...'
      }
      return content
    }

    const shortenRemark = (remark: string) => {
      if (remark.length > 50) {
        remark = remark.substring(0, 50) + '...'
      }
      return remark
    }

    return {
      previousFeedback,
      nextFeedback,
      debounceSearch,
      currentPage,
      results,
      count,
      doCall,
      text,
      type,
      status,
      selected,
      slug,
      checkBoxChanged,
      isDisplayable,
      checkIfIdAlreadyExistsInScheduleBlock,
      onRowSelected,
      checkIfActiveDate,
      reloadData,
      can,
      selectedTags,
      filterKey,
      TagRepository,
      displayFeedbackViewSidebar,
      isFeedbackViewVisible,
      feedbackId,
      shortenRemark,
      shortenContent,
      clearRows,
      table,
      onRowClicked,
      tableKey,
      refreshTable,
      lastUsedCellKey
    }
  }
})
