











































import Vue from 'vue'
import usePermissions from '../../composables/usePermissions'
import useTenants from '../../composables/useTenants'
import { defineComponent, ref } from '@vue/composition-api'
import { RepositoryFactory } from '@wellbeingapp/shared'
import { useRouter } from '../../composables/useRouter'

export default defineComponent({
  name: 'custom-sidebar',
  setup (props, { root }) {
    const { can } = usePermissions()
    const { list, isTenantSelected, selectTenant } = useTenants()
    const demographicFeature = ref<boolean>(false)
    const feedbackFeature = ref<boolean>(false)
    const { route } = useRouter()
    const logout = () => {
      Vue.$keycloak.logout()
    }
    demographicFeature.value = RepositoryFactory.getFeatures().demographic
    feedbackFeature.value = RepositoryFactory.getFeatures().feedback
    const items = ref<Array<any>>([
      { name: 'calendar', link: 'calendar', icon: 'calendar3', permission: 'schedule.view_all_scheduleblock', visible: true },
      { name: 'questions-overview-screen', link: 'questions-overview-screen', icon: 'question-circle-fill', permission: 'questions.view_question', visible: true },
      { name: 'tips-overview-screen', link: 'tips-overview-screen', icon: '', permission: 'tips.view_tip', visible: true },
      { name: 'demographic', link: 'demographic-questions-overview-screen', icon: 'people-fill', permission: 'demographics.view_demographicproperty', visible: demographicFeature.value },
      { name: 'feedback-overview-screen', link: 'feedback-overview-screen', icon: 'chat-fill', permission: 'feedback.view_feedback', visible: true }, // visible: feedbackFeature.value
      { name: 'export-overview-screen', link: 'export-overview-screen', icon: 'chat-fill', permission: 'feedback.view_feedback', visible: true },
      { name: 'analytics-overview-screen', link: 'analytics-overview-screen', icon: 'chat-fill', permission: null, visible: true }
    ])

    return {
      items,
      logout,
      can,
      list,
      isTenantSelected,
      selectTenant
    }
  }
})
