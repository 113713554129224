import { BaseEntityModel } from './baseEntityModel'
import EntityModel from '../../interfaces/entityModel'

type TenantModelStorageType = {
  name?: string
  slug?: string
  backgroundcolor?: string
  primarycolor?: string
  secondarycolor?: string
  baseUrl?: string
  apiSuffix?: string
  oidc_clientId?: string
  oidc_realm?: string
  oidc_redirectUrl?: string
  autoDiscoverUrl?: string
  logoUrl?: string
  activationCode?: string
  schedule_daily_start_time?: string
}

export default class TenantModel
  extends BaseEntityModel
  implements EntityModel<TenantModel> {
  constructor (
    public slug?: string,
    public name?: string,
    public primarycolor?: string,
    public secondarycolor?: string,
    public backgroundcolor?: string,
    public apiSuffix?: string,
    public autoDiscoverUrl?: string,
    public baseUrl?: string,
    public oidc_clientId?: string,
    public oidc_realm?: string,
    public oidc_redirectUrl?: string,
    public logoUrl?: string,
    public activationCode?: string,
    public schedule_daily_start_time?: string
  ) {
    super(slug)
  }

  public static deserialize (input: any): TenantModel {
    return new TenantModel(
      input.slug,
      input.name,
      input.primarycolor,
      input.secondarycolor,
      input.backgroundcolor,
      input.apiSuffix,
      input.autoDiscoverUrl,
      input.baseUrl,
      input.oidc_clientId,
      input.oidc_realm,
      input.oidc_redirectUrl,
      input.logoUrl,
      input.activationCode,
      input.schedule_daily_start_time
    )
  }
}
